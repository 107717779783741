@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.show-count{
	/* position: absolute; */
	display: block;
}
.menu-delete:hover{
	color: #e61212;
}
.hide-cont{
	display: none;
}
.flags-cont .single-img-home {
    height: 44px;
    width: 400px;
    width: auto;
    margin-right: 10px;
    padding: 4px;
    position: relative;
    display: flex;
    gap: 10px;
}
.custom-points-head{
	position: fixed;
	/* width: 100%; */
	width: auto;
	right: 3%;
	display: flex;
    justify-content: flex-start;
    align-items: flex-end !important;
    flex-direction: column !important;
	/* flex-flow: row-reverse; */
	z-index: 100;
	top: 11%;
}
.product-country{
	height: 40px;
    width: 40px;
    overflow: hidden;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 10px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	left: 20px;
}

.product-country img{
	width: 100%;
    height: 100%;
}

.loadMore{
	margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #646464;
	cursor: pointer;
}
.loadMore:hover{
	text-decoration: underline;
	color: #e61212;
}

.world-wrapper .world-img-wrapper > div > div > div > div > div{
	width: unset !important;
}
.world-wrapper .world-img-wrapper .scene-container canvas{
	width: unset !important;
}
/* animation: rotation 8s infinite linear; */
	/* animation: spin 30s linear alternate infinite; */
	/* transform-style: preserve-3d; */

/* .rsm-zoomable-globe {
  transform: rotate(15deg);
  animation: rotate 10s linear infinite;
  }
  
  @keyframes rotate {
	0%   { background-position: 0 0; } 
	100% { background-position: 545px 0; }
  } */

  /* .rsm-zoomable-globe{
	animation: spin 3.5s infinite linear
  } */

  /* @keyframes spin{
  0%{background-position-x: 0}
  100%{
    background-position-x: 380px}
  } */

  /* @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
} */
.world-img-wrapper .rsm-zoomable-globe{
	/* animation: ror 15s infinite; */
}
@keyframes ror {
	0%{
		/* transform: rotateY(0deg) */
		transform: rotateY(0deg);
		transform-style: preserve-3d;
	}
	99%{
		/* transform: rotateY(360deg); */
		transform: rotateY(360deg);
		transform-style: preserve-3d;
	}
}

.cart-data {
    display: flex;
	border: 1px dashed #c5c5c5;
    width: 175px;
    padding: 5px 15px;
    position: absolute;
    right: 95px;
    top: 5px;
    border-radius: 22px;
}
 .cart-data img{
	width: 30px;
	transition: transform .2s;
	border-radius: 50%;
}
 .cart-data img:hover {
	transform: scale(1.5); 
  }

.cart-data .text-cont{
	border-right: 1px dashed #c5c5c5;
	margin-right: 4px;
}
 .cart-data p {
	color: #2A263D;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Ivy Mode';
	margin: 0;
	margin-right: 10px;
	cursor: pointer;
}

/* .selected-items-cont{
	display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0px 0px 0px;
} */
.selected-items-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 0px 0px;
}

/* .selected-items{
	border: 1px dashed #c5c5c5;
	border-radius: 22px;
    padding: 5px 10px;
    font-size: 16px;
    font-family: "Ivy Mode";
} */
.selected-items span{
		/* background: #e61212; */
		color: #000;
		font-family: "Ivy Mode";
		position: relative;
		/* clip-path: polygon(0 0, 100% 0, 88% 47%, 100% 100%, 0 100%, 13% 46%); */
		margin: 0px auto;
		display: table;
		/* padding: 8px 28px; */
		margin-right: 10px !important;
		font-size: 20px;
	}
	.AllMenu-Main-container .selected-items {
		display: flex;
		align-items: center;
		border: 1px dashed #c5c5c5;
		border-radius: 25px;
		padding: 8px 22px;
	}
	
	.AllMenu-Main-container .selected-items img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		border-radius: 50%;
	}

.selected-items img{
	width: 30px;
	transition: transform .2s;
}
.selected-items img:hover {
	transform: scale(1.5); 
  }

.title-cont{
	display: flex;
    justify-content: center;
    align-items: center;
}
.optionalTitle{
	margin-left: 50px;
}
.optionalTitle h2{
	/* background: #514d4d !important; */
	background: transparent !important;
    color: #e61212 !important;
	border: 2px solid #e61212 !important;
	font-weight: bold;
    font-family: "Ivy Mode";
    position: relative;
    clip-path: unset !important;
    margin: 0px auto;
    display: table;
    padding: 3px 48px;
    font-size: 25px;
}
.dp-non{
	display: none;
}
.left-right{
	position: absolute;
    right: 0;
    top: 60px;
}
.right-left{
	position: absolute;
    top: 60px;
}

.loader-container{
	text-align: center;
}
.down-icon{
	font-size: 18px;
}
.down-icon:hover{
	color: #e61212;
}
.flags-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flags-cont .single-img{
	height: 44px;
	/* width: 40px; */
	width: auto;
	overflow: hidden;
	margin-right: 10px; 
	padding: 4px;
}
.flags-cont .active-flag{
	border: 1px solid #000;
	border-radius: 12px;
}


.flags-cont .single-img img{
    /* margin-right: 5px; */
	/* width: 100%; */
	height: 100%;
}
.Custom-select{
    border: 4px solid #fc0c0c;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	/* background: #cfcfcf; */
	/* filter: unset !important; */
	 filter: grayscale(1) !important;
}
.check-icon{
	color: green;
}
.header-logo {
	text-align: center;
}
/* all menu  */
.AllMenu-Main-container{
    padding: 0px 64px;
}
.AllMenu-Main-container .cart-data {
    display: flex;
	border: 1px dashed #c5c5c5;
    width: 250px;
    padding: 5px 15px;
    position: absolute;
    right: 95px;
    top: 5px;
    border-radius: 22px;
}
.AllMenu-Main-container .cart-data img{
	width: 30px;
	transition: transform .2s;
	border-radius: 50%;
}
.AllMenu-Main-container .cart-data img:hover {
	transform: scale(1.5); 
  }

.AllMenu-Main-container .cart-data p {
	color: #2A263D;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Ivy Mode';
	margin: 0;
	margin-right: 10px;
}
.AllMenu-Main-container .cart-data .text-cont{
	border-right: 1px dashed #c5c5c5;
	margin-right: 4px;
}
.AllMenu-Main-container .tabs-container{
	display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: dotted;
}

.AllMenu-Main-container .main-title{
	font-size: 30px;
    font-family: 'Ivy Mode bold';
    color: #FF0000;
	margin-bottom: 0px;
}

.AllMenu-Main-container .dish-row{
	padding: 0px 71px;
	/* transition: all 0.5s ease-in-out; */
}

.AllMenu-Main-container .doted-right{
	/* border-right: 1px dashed #cfcfcf;   */
	/* transition: all 0.5s ease-in-out; */
}

.AllMenu-Main-container .tabs-container p{
	margin: 0;
}

.AllMenu-Main-container .addtocart-Btn{
    background: #e61212;
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    padding: 12px 33px;
    margin: 24px 0px;
    border-radius: 50px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0px;
    width: 200px;
	/* width: 150px; */
}

.AllMenu-Main-container .btn-container{
	text-align: center;
}

.AllMenu-Main-container .hide{
	display: none;
}
.AllMenu-Main-container .show{
	display: block;
}
/* all menu  */
.AllMenu-Details-container{
    padding: 0px 64px;
}
.AllMenu-Details-container .right-cont .main-title{
	color: #FF0000;
    font-size: 35px;
    font-family: 'Ivy Mode';
}

.AllMenu-Details-container .right-cont .main-para{
	font-size: 14px;
    font-family: 'Poppins';
    color: #000000;
	margin-bottom: 32px;
}

.AllMenu-Details-container .right-cont .md-title{
	color: #FF0000;
    font-size: 25px;
    font-family: 'Ivy Mode';
}

.AllMenu-Details-container .left-cont{
    padding: 22px 16px;
	border: 1px dashed #c5c5c5;
}

.AllMenu-Details-container .left-cont .left-title{
	font-size: 22px;
    color: #FF0000;
    font-family: 'Ivy Mode';
    margin-bottom: 12px;
}

.AllMenu-Details-container textarea{
    width: 100%;
    height: 100px;
    padding: 14px 14px;
	border: 1px dashed #c5c5c5;
}


.AllMenu-Details-container .client-review-wrapper ul {
	padding: 0px;
	list-style: none;
	overflow-y: scroll;
    height: 250px;
}
.AllMenu-Details-container .client-review-wrapper ul li .client-review-content {
	display: flex;
	margin: 20px 0px;
}

.AllMenu-Details-container
	.client-review-wrapper
	ul
	li
	.client-review-content
	.client-img-wrapper {
	flex: 0 0 8%;
}

.AllMenu-Details-container
	.client-review-wrapper
	ul
	li
	.client-review-content
	.client-content-wrapper {
	flex: 0 0 88%;
	margin-left: 20px;
}

.AllMenu-Details-container.client-review-wrapper {
	padding-top: 35px;
}
.AllMenu-Details-container .client-review-wrapper .client-review-content .client-content-wrapper p {
	color: #000;
	font-family: "Poppins";
	font-size: 14px;
	margin-bottom: 7px;
}
.AllMenu-Details-container .client-review-wrapper .client-review-content .client-content-wrapper h6 {
	color: #ff0000;
	font-family: "Ivy Mode bold";
	font-size: 12px;
}
.AllMenu-Details-container .client-review-wrapper .client-img-wrapper figure  {
	width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #fff;
	box-shadow: 0px 2px 3px 1px #2c2c2c38;
    /* box-shadow: -1px 4px 7px 0px #00000038; */
}
.AllMenu-Details-container .client-review-wrapper .client-img-wrapper figure img {
	height: 100%;
    width: 100%;
}

.AllMenu-Details-container .addtocart-Btn{
    background: #e61212;
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    padding: 12px 33px;
    margin: 0px 0px;
    border-radius: 50px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0px;
    width: 200px;
}

.AllMenu-Details-container .left-cont .main-title{
	font-size: 22px;
    color: #FF0000;
    font-family: 'Ivy Mode';
    font-weight: 600;
}

.AllMenu-Details-container .left-cont .main-banner{
	width: 100%;
	height: 220px;
    overflow: hidden;
}
.AllMenu-Details-container .left-cont .main-banner img{
	width: 100%;
	height: 220px;
}
.AllMenu-Details-container .left-cont .all-images{
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
}

.AllMenu-Details-container .left-cont .all-images .small-banner{
	width: 80px;
    height: 80px;
    overflow: hidden;
	margin-top: 10px;
    /* margin-right: 13px; */
}

.AllMenu-Details-container .left-cont .all-images .small-banner img{
	width: 80px;
	height: 80px;
}

.AllMenu-Details-container .left-cont  .remove-item{
	position: absolute;
    top: 10;
    top: 14px;
    color: #e61212;
	background: whitesmoke;
}

.AllMenu-Details-container .left-cont-summary{
    padding: 22px 16px;
	border: 1px dashed #c5c5c5;
    margin: 14px 0px;
}

.AllMenu-Details-container .left-cont-summary .order-summary-title{
	color: #FF0000;
    font-size: 17px;
    font-family: 'Ivy Mode';
    font-weight: 600;
}

.AllMenu-Details-container .left-cont-summary .sub-text{
	font-size: 16px;
    color: #000000;
    font-family: 'Ivy Mode';
}
.AllMenu-Details-container .left-cont-summary .sub-text{
	font-size: 16px;
    color: #000000;
    font-family: 'Ivy Mode';
	font-weight: 600;
}
.AllMenu-Details-container .left-cont-summary .para-text{
	font-size: 12px;
    color: #000000;
    font-family: 'Poppins';
	font-style: italic;
}

.AllMenu-Details-container .submit-Btn {
	background: #e61212;
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    padding: 12px 28px;
    margin: 12px 0px;
    border-radius: 50px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0px;
    width: 150px;
}

.AllMenu-Details-container .edit-meal-icon{
	position: absolute;
    right: 50px;
    top: 70px;
    background: aliceblue;
    padding: 2px;
    font-size: 26px;
}

.AllMenu-Details-container .edit-meal-icon:hover{
	color: #e61212;
}


/* universal css starts here */

body i::before {
	font-family: "FontAwesome";
}
/* html,body{
	transition: all 0.4s ease-in-out;
} */
.hide{
	display: none;
}
.show{
	display: unset;
}

.notFound-container{
    text-align: center;
 }
 .notFound-container img{
    width: 38%;
 }

.review-btn-container{
    margin-top: 21px;
}
.review-btn-container .review-btn{
	background: #e61212;
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    padding: 10px 32px;
    margin: 0 5px;
    border-radius: 50px;
    cursor: pointer;
}
.review-btn-container .review-btn:hover{
	background: #1b1b1b;
}

.modal-dialog {
    max-width: 1250px;
	/* height: 92vh; */
    margin: 1.75rem auto;
}
.modal-content {
	max-width: 1250px;
		/* height: 92vh; */
}

.modal-close{
	/* position: absolute;
    right: 0; */
	align-self: flex-end;
    margin-right: 8px;
    margin-top: 8px;
}

.heading-wrapper{
	text-align: center;
}
.heading-wrapper h2{
	font-size: 30px;
    color: #FF0000;
    font-family: 'Ivy Mode';
    font-weight: 600;
}
.heading-wrapper p{
    font-size: 16px;
    color: #000;
    font-family: 'Poppins';
    text-align: center;
    padding: 4px 80px;
    margin-bottom: 26px;
}
/* universal css ends here */
/* loading starts here */
#introduction {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 105;
	background-color: #000;
	cursor: pointer;
	overflow: hidden;
	top: 0;
	left: 0;
}

#introduction .image-container {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	position: relative;
}

@media (max-width: 768px) {
	#introduction .image-container {
		height: 80vh;
	}
}

#introduction .banner-logo {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	max-width: 1600px;
}

#introduction .slide {
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0;
	bottom: 0;
	background: #fff;
}

#introduction .slide-2 {
	height: 0;
}
.slide-1 {
	z-index: 999;
}

.image-container {
	background: #02272fd9;
	backdrop-filter: blur(28px);
}

#content {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 36px;
	font-weight: 600;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* loading ends here */
header .mobile-header {
	width: 30%;
	height: 100%;
	position: fixed;
	background: #ffffff9c;
	top: 0;
	z-index: 1;
	left: 0;
	padding-top: 30px;
	transition: 0.7s;
	transform: translateX(-100%);
	backdrop-filter: blur(20px);
}
header .mobile-header.show {
	transform: translateX(0%);
}
header#header .menu-heaading-wrapper h2 {
	color: #1e403b;
	font-family: "Ivy Mode bold";
	font-size: 42px;
	font-weight: 700;
}
header#header .mobile-header .menu-wrapper {
	margin-left: 94px;
}
header .mobile-header ul.mobile-nav {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
	margin-top: 37px;
}
header .mobile-header .cancel {
	background: #fff;
	width: 35px;
	text-align: center;
	/* height: 35px; */
	line-height: 55px;
	margin: 0px 20px 20px auto;
}
header .mobile-header ul.mobile-nav li.nav-item {
	padding: 10px 0;
	border: 0;
	/* border-bottom: 1px solid #dddddd4a; */
}
header .mobile-header ul.mobile-nav li.nav-item a {
	color: #1e403b;
	font-family: "Poppins";
	font-size: 18px;
	padding: 0 0px;
	font-weight: 500;
	text-decoration: none;
}
header#header .menu-wrapper ul.mobile-nav .btn {
	background: transparent;
	padding: 0px;
	color: #1e403b;
	font-family: "Poppins";
	font-size: 18px;
	padding: 0 0px;
	font-weight: 500;
	text-decoration: none;
}
header#header .menu-wrapper li.nav-item span {
	color: #e61212;
	margin-right: 10px;
}
header#header {
	position: absolute;
	/* position: fixed; */
	width: 100%;
	padding: 44px 0px;
	z-index: 6;
}
header#header .canvas-icon img {
	cursor: pointer;
}
/* .rsm-geographies {
    animation: rotation 5s linear 0s infinite;
}
@keyframes rotation {
	from {
	  transform: translateX(0);
	}
	
	from {
	  transform: translateX(-50%);
	}
  } */
header#header .menu-heaading-wrapper a {
	text-decoration: none;
}
/* Prelaoder */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #02272fd9;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #f2f2f2;
	border-top: 6px solid #0068b4;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* font family starts here */
@font-face {
	font-family: "Ivy Mode";
	src: url("../fonts/IvyMode-Regular.ttf");
}
@font-face {
	font-family: "Ivy Mode bold";
	src: url("../fonts/IvyMode-Bold.ttf");
}
/* font family ends here */
/* banner starts here */
.banner-sec {
	background-image: url("../img/banner-bg.png");
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
}
.banner-sec .world-wrapper .world-flag-wrapper button {
	background: transparent;
	border: 0px;
	transition: all 0.3s ease-in-out;
	outline: none;
}
.banner-sec .world-wrapper {
	position: relative;
	/* z-index: 100; */
	z-index: unset;
}
.banner-sec .world-wrapper .world-flag-wrapper {
	position: absolute;
	top: 50%;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: space-evenly;
}
.banner-sec .all-dish-wrapper .dish-wrapper button {
	background: transparent;
	border: 0px;
	padding: 0px;
}
.banner-sec .all-dish-wrapper .dish-wrapper button:focus {
	outline: none;
	box-shadow: none;
	border: 0px;
}

.banner-sec .all-dish-wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	/* margin-top: 108px; */
	opacity: 1;
	transition: all 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	z-index: 2;
}
/* .banner-sec .dish-wrapper .dish-img-wrapper:hover img {
	animation: dishRotate 10s infinite ease-in-out;
} */

.banner-sec .dish-wrapper .dish-img-wrapper img {
	animation: dishRotate 10s infinite ease-in-out;
}

.all-dish-wrapper .dish-wrapper .dish-content-wrapper {
	text-align: center;
	background: #fffdfbe3;
	position: relative;
	top: -90px;
	backdrop-filter: blur(36px);
	padding: 10px 0px;
	width: 104%;
	left: 0px;
}
.all-dish-wrapper .dish-wrapper .dish-content-wrapper h4 {
	color: #000000;
	font-size: 16px;
	font-family: "Ivy Mode bold";
	font-weight: 700;
	margin: 0px;
}
.banner-sec .world-flag-wrapper .usa1-flag-wrapper {
	position: absolute;
	left: 15%;
	top: -18%;
}
.banner-sec .world-flag-wrapper .italy-flag-wrapper {
	position: absolute;
	top: 26%;
	left: 19%;
}
.banner-sec .world-flag-wrapper .uk-flag-wrapper {
	left: 40%;
	position: absolute;
	top: -40%;
}
.banner-sec .world-wrapper .usa-flag-wrapper {
	position: absolute;
	left: 67%;
	top: -30%;
}
.banner-sec .world-flag-wrapper .russia-flag-wrapper {
	position: absolute;
	top: 9%;
	left: 54%;
}
.banner-sec .world-flag-wrapper .germany-flag-wrapper {
	position: absolute;
	right: 14%;
}
.banner-sec .world-flag-wrapper .germany-flag-wrapper-6 {
	position: absolute;
	right: 530px;
	top: -56px;
}

.banner-sec .all-dish-wrapper .dish1-wrapper {
	position: absolute;
	top: 174%;
	left: -73px;
	transform: rotate(-45deg);
}
.banner-sec .all-dish-wrapper .dish2-wrapper {
	position: absolute;
	top: 99%;
	left: 2%;
}
.banner-sec .all-dish-wrapper .dish3-wrapper {
	position: absolute;
	top: 49%;
	left: 17%;
}
.banner-sec .all-dish-wrapper .dish4-wrapper {
	position: absolute;
	top: 10%;
    left: 42%;
}
.banner-sec .all-dish-wrapper .dish5-wrapper {
	position: absolute;
	top: 33%;
	left: 60%;
}
.banner-sec .all-dish-wrapper .dish6-wrapper {
	position: absolute;
	right: 7%;
	top: 73%;
}
.banner-sec .all-dish-wrapper .dish7-wrapper {
	position: absolute;
	top: 155%;
	right: -35px;
	transform: rotate(45deg);
}
.banner-sec .all-dish-wrapper .dish2-wrapper .dish-content-wrapper {
	top: -114px;
}
.banner-sec .all-dish-wrapper .dish3-wrapper .dish-content-wrapper {
	top: -114px;
}
.banner-sec .all-dish-wrapper .dish4-wrapper .dish-content-wrapper {
	top: -114px;
}
.banner-sec .all-dish-wrapper .dish5-wrapper .dish-content-wrapper {
	top: -114px;
}
.banner-sec .all-dish-wrapper .dish6-wrapper .dish-content-wrapper {
	top: -114px;
}
.banner-sec .world-wrapper button.uk span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
	margin-left: -25px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}
.banner-sec .world-wrapper button.usa span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
    margin-left: -20px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}
.banner-sec .world-wrapper button.italy span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
    margin-left: -21px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}

.banner-sec .world-wrapper button.usa2 span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
    margin-left: -21px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}

.banner-sec .world-wrapper button.russia span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
    margin-left: -21px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}

.banner-sec .world-wrapper button.germany span {
	background: #f3fee8ab;
	height: 60px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
    margin-left: -21px;
	position: relative;
	font-family: "Ivy Mode";
	z-index: 0;
	font-size: 14px;
	font-weight: 700;
	backdrop-filter: blur(19px);
	border-radius: 0px 10px 10px 0px;
	opacity: 0;
	transform: translateX(-17px);
	transition: all 0.3s ease-in-out;
}


.banner-sec .world-flag-wrapper button:hover span {
	opacity: 1;
	transform: translateX(0px);
}
.banner-sec .world-flag-wrapper img {
	z-index: 2;
	position: relative;
}
/* banner ends here */

/* footer starts here */
footer.footer {
	background: #02272fd9;
	backdrop-filter: blur(28px);
	padding: 10px 0px;
	margin-top: -35px;
}
footer.footer .contact-info-wrapper ul {
	padding: 0;
	list-style: none;
	display: flex;
	margin: 0px;
}
footer.footer .contact-info-wrapper ul li {
	margin: 0px 16px 0px 0px;
}
footer.footer .contact-info-wrapper ul li a {
	color: #ffffff;
	font-size: 12px;
	text-decoration: none;
	font-family: "Poppins";
}
.footer .copyright-wrapper p {
	text-align: end;
	font-size: 12px;
	font-family: "Poppins";
	color: #fff;
	font-weight: 500;
	margin: 0px;
}
/* footer ends here */
.banner-sec button.btn.btn-primary {
	position: relative;
	z-index: 9;
}
/* animation starts here */

@keyframes dishRotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* animation ends here */

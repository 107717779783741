#courseModal .modal-dialog {
	max-width: 75%;
}
#courseModal .modal-dialog .modal-header {
	border: 0;
}
#courseModal .modal-dialog .modal-header button.close {
	float: unset !important;
	position: absolute;
	right: 0;
}
#courseModal .modal-dialog .modal-header button.close {
	color: #000 !important;
	opacity: 1 !important;
	right: 13px;
	top: 10px;
}
#courseModal .course-box button {
	background: transparent;
	border: 0px;
	padding: 0px;
}
#courseModal .modal-dialog .cart-content .heading-wrapper {
	text-align: center;
	max-width: 70%;
	margin: 0px auto 20px;
}
#courseModal .modal-dialog .cart-content .heading-wrapper h2 {
	font-family: "Ivy Mode bold";
	color: #ff0000;
	font-size: 35px;
}
#courseModal .modal-dialog .cart-content .heading-wrapper p {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
#courseModal .courses .title-wrapper {
	text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
}
#courseModal .courses .title-wrapper h2 {
	background: #e61212;
    color: #fff;
    font-family: "Ivy Mode";
    position: relative;
    clip-path: polygon(0 0, 100% 0, 88% 47%, 100% 100%, 0 100%, 13% 46%);
    margin: 0px auto;
    display: table;
    padding: 3px 48px;
    font-size: 25px;
}
#courseModal .courses .course-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	transition: all 0.5s ease-in-out;
}
#courseModal .courses .course-flex .course-box {
    width: 100%;
    margin: 0 0px;
    overflow: hidden;
    margin-top: 0px;
    /* filter: grayscale(1); */
}
#courseModal .courses .course-flex .course-box:hover {
    /* border: 4px solid #fc0c0c; */
    /* filter: grayscale(1); */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 
}
#courseModal .courses .course-flex .course-box .img_box {
    position: relative;
    overflow: hidden;
    height: 130px;
    width: 100%;
    height: 152px;
}
#courseModal .courses .course-flex .course-box .img_box2 {
    position: relative;
    overflow: hidden;
    height: 300px;
    width: 100%;
}

#courseModal .courses .course-flex .course-box .img_box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
#courseModal .courses .course-flex .course-box .img_box2 img {
    /* height: 300px; */
    /* width: 100%; */
    object-fit: cover;
}

#courseModal .courses .course-flex .course-box .img_box figure {
	margin-bottom: 0;
}
#courseModal .courses .course-flex .course-box .img_box .price {
	background: #e61212;
    color: #fff;
    font-family: "Ivy Mode";
    font-size: 16px;
    position: absolute;
    bottom: -29%;
    width: 50%;
    height: 62%;
    border-radius: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0px auto;
    padding-top: 22px;
}

#courseModal .courses .course-flex .course-box .img_box .price2 {
    background: #e61212;
    color: #fff;
    font-family: "Ivy Mode";
    font-size: 16px;
    position: absolute;
    bottom: -29%;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0px auto;
    padding-top: 22px;
}

#courseModal .courses .course-flex .course-box .content {
	padding-top: 25px;
	text-align: center;
}
#courseModal .courses .course-flex .course-box .content h3 {
	font-size: 20px;
	color: #000000;
	font-family: "Ivy Mode";
	font-weight: 600;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
#courseModal .courses .course-flex .course-box .content p {
	font-size: 10px;
	color: #0000007a;
	font-family: "Poppins";
}
#courseModal .all-courses {
    /* height: 72vh; */
    /* overflow: hidden; */
    height: 53vh;
	overflow-y: scroll;
    margin-bottom: 12px;
    margin-top: 22px;
}
::-webkit-scrollbar {
	width: 0.4em;
}
::-webkit-scrollbar-track {
	background: #ddd;
}
::-webkit-scrollbar-thumb {
	background-color: #000000;
}
.all-courses .col-lg-6 {
    transition: all 1s ease-in-out;
}
.all-courses .col-lg-12 {
    transition: all 1s ease-in-out;
}
#courseModal .all-courses .courses {
	padding-top: 8px;
}
#courseModal .all-courses .title {
	text-align: center;
	padding-top: 35px;
}
#courseModal .all-courses .title h4 {
	color: #ff0000;
	font-size: 35px;
	font-family: "Ivy Mode";
}
#courseModal .all-courses .title p {
	font-family: "Poppins";
	color: #000000;
}
